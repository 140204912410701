<template>
    <div class="re-label-view">
        <div class="re-label-header">
            <span class="re-label-title">补打标签</span>
            <i class="el-icon-close" @click="closeWindow"></i>
        </div>
        <div class="re-label-body">
            <el-row :gutter="10" class="search-box">
                <el-col :span="9" class="search-item">
                    <span class="search-label">货号/简码</span>
                    <el-input
                        size="mini"
                        v-model="spu"
                        placeholder="请输入货号或简码"
                        clearable
                        @change="getList()"
                    ></el-input>
                </el-col>
                <el-col :span="9" class="search-item">
                    <span class="search-label">货品名称</span>
                    <el-input
                        size="mini"
                        v-model="title"
                        placeholder="请输入货品名称"
                        clearable
                        @change="getList()"
                    ></el-input>
                </el-col>
                <el-col :span="6" class="top-btns">
                    <el-button size="small" @click="init()">重置</el-button>
                    <el-button size="small" type="primary" @click="getList()">搜索</el-button>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :span="14">
                    <div class="content-left">
                        <el-table
                            ref="tableIns"
                            :data="tableList"
                            border
                            class="table-box"
                            height="620px"
                            size="medium"
                            align="center"
                            v-loading="loading"
                            @row-click="rowClick"
                            :row-class-name="rowClassNameFn"
                            :highlight-current-row="false"
                        >
                            <el-table-column prop="spu" label="货号" :show-overflow-tooltip="true"> </el-table-column>
                            <el-table-column prop="goodsCode" label="简码" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column prop="title" label="货品名称" :show-overflow-tooltip="true">
                            </el-table-column>
                            <el-table-column prop="color" label="颜色" :show-overflow-tooltip="true"> </el-table-column>
                        </el-table>
                        <el-pagination
                            small
                            background
                            @current-change="handleCurrentChange"
                            @size-change="handleSizChange"
                            :current-page="page"
                            :page-size="pageSize"
                            :pager-count="5"
                            :page-sizes="[100, 200, 500, 1000]"
                            layout="sizes,prev,pager,next"
                            :total="total"
                        >
                        </el-pagination></div
                ></el-col>
                <el-col :span="10">
                    <div class="content-right">
                        <div class="bar-code-list">
                            <template v-if="checkData.goodDetailInfoList && checkData.goodDetailInfoList.length">
                                <span
                                    v-for="item in checkData.goodDetailInfoList"
                                    :key="item.size"
                                    :class="{
                                        'bar-code-item': true,
                                        'is-check': checkCode === item.size
                                    }"
                                    @click="sizeChange(item)"
                                >
                                    {{ item.size }}
                                </span>
                            </template>
                        </div>
                        <div class="preview-box">
                            <div class="preview-item">
                                <div class="preview-title">货品图片</div>
                                <div class="image-content">
                                    <template v-if="imgUrlList && imgUrlList.length > 1">
                                        <span class="btn left-btn el-icon-arrow-left" @click="toLeft"></span>
                                        <span class="btn right-btn el-icon-arrow-right" @click="toRight"></span>
                                    </template>
                                    <div class="img-preview">
                                        <div class="url-list" v-show="imgUrlList && imgUrlList.length">
                                            <img
                                                v-for="(item, index) in imgUrlList"
                                                :key="index"
                                                :src="item"
                                                loading="lazy"
                                            />
                                        </div>
                                        <div
                                            v-show="!(imgUrlList && imgUrlList.length)"
                                            class="url-none el-icon-picture-outline"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="print-preview">
                                <div class="preview-title">打印预览</div>
                                <webview
                                    class="print-preview-content"
                                    v-if="tagData && tagData.barCode"
                                    :src="webViewPreview"
                                ></webview>

                                <div class="goods-none" v-else>请选择货品尺码</div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <DoReceipts
            v-if="DoReceiptsModal"
            :webViewUrl="webViewUrl"
            title="补打标签"
            webviewHeight="400px"
            @close="DoReceiptsModal = false"
        ></DoReceipts>
        <div class="re-label-footer">
            <el-button size="small" type="primary" @click="printTag()">打印标签</el-button>
        </div>
    </div>
</template>

<script>
import { ipcApiRoute } from '@/api/main'
import { ipc } from '@/utils/ipcRenderer'
import { erp2SpuDemandReLabel } from '@/libs/http/modules/erp'
import lodash from 'lodash'
import request from '@/libs/http/index'
import DoReceipts from '@/views/salesRecord/components/DoReceipts.vue'
export default {
    name: 'reLabelDialog',
    components: { DoReceipts },
    data() {
        return {
            loading: false,
            dialogVisible: true,
            tableList: [],
            total: 0,
            page: 1,
            pageSize: 100,
            spu: '',
            title: '',
            checkData: {},
            tagData: {},
            checkCode: 4,
            curIndex: 0,
            intervalId: null,
            DoReceiptsModal: false,
            webViewUrl: '',
            webViewPreview: ''
        }
    },
    computed: {
        imgUrlList() {
            let list = []
            if (this.checkData.imgUrl) {
                if (this.checkData.imgUrl.length === 1) {
                    list = this.checkData.imgUrl
                } else if (this.checkData.imgUrl.length > 1) {
                    list = [...this.checkData.imgUrl, this.checkData.imgUrl[0]]
                }
            }
            return list
        },
        box() {
            return document.querySelector('.url-list')
        },
        templateCodeInfoUrl() {
            return this.$cache.local.get('templateCodeInfoUrl')
        },
        printer() {
            return this.$cache.local.get('printerModel')
        },
        printCode() {
            return this.$cache.local.get('skuLableReceiptCode')
        },
        printCount() {
            return this.$cache.local.get('skuLableReceiptCount')
        }
    },
 
    methods: {
        closeWindow() {
            this.$confirm('提示', {
                title: '提示',
                message: '此操作将会关闭当前窗口，是否确认关闭？',
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
                .then((res) => {
                    this.init()
					ipc.invoke(ipcApiRoute.closeWindow, 'openReLabel').then(r => {})
                })
                .catch((err) => {
                    console.log(`用户点击取消${err}`)
                })
        },
        show() {
            this.dialogVisible = true
        },
        init() {
            this.tableList = []
            this.total = 0
            this.page = 1
            this.pageSize = 100
            this.spu = ''
            this.title = ''
            this.checkCode = ''
            this.curIndex = 0
            this.intervalId = null
            this.checkData = {}
            this.tagData = {}
            this.webViewPreview = ''
        },
        handleClose() {
            this.init()
            this.dialogVisible = false
        },
        handleCurrentChange(e) {
            this.page = e
            this.searchList()
        },
        handleSizChange(e) {
            this.page = 1
            this.pageSize = e
            this.searchList()
        },
        getList() {
            this.page = 1
            this.tagData = {}
            this.webViewPreview = ''
            this.checkData = {}
            this.searchList()
        },
        searchList() {
            let reqData = {
                spu: this.spu,
                title: this.title,
                page: this.page,
                pageSize: this.pageSize
            }
            this.loading = true
            erp2SpuDemandReLabel(reqData)
                .then((data) => {
                    this.tableList = data.items || []
                    this.total = +data.totalItem || 0
                    this.loading = false
                    if (data.items.length === 1) {
                        this.rowClick(data.items[0])
                    }
                })
                .catch((error) => {
                    this.$message.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        //行单击
        rowClick(row) {
            this.checkData = row
            if (this.checkData.goodDetailInfoList && this.checkData.goodDetailInfoList.length) {
                this.sizeChange(this.checkData.goodDetailInfoList[0])
            }
            this.$nextTick(() => {
                this.curIndex = 0
                this.box.style.left = '0px'
            })
        },
        sizeChange(data) {
            this.tagData = {
                ...lodash.pick(this.checkData, ['spu', 'title', 'color', 'goodsCode']),
                ...lodash.pick(data, ['size', 'barCode', 'skuId'])
            }
            this.checkCode = data.size
            this.$nextTick(() => {
                let params = {
                    id: this.tagData.skuId
                }
				this.webViewPreview = `${request.printPreviewRoot}?printerName=${this.printer}&templateCode=${this.printCode}&printCount=0&data=${encodeURI(JSON.stringify(params))}`
            })
        },

        toLeft() {
            this.curIndex -= 1
            if (this.curIndex < 0) {
                this.box.style.left = -((this.imgUrlList.length - 1) * 240) + 'px'
                this.curIndex = this.imgUrlList.length - 2
            }
            this.animate(this.box, -(this.curIndex * 240))
        },
        toRight() {
            if (this.curIndex == this.imgUrlList.length - 1) {
                this.box.style.left = '0px'
                this.curIndex = 1
            } else {
                this.curIndex += 1
            }
            this.animate(this.box, -(this.curIndex * 240))
        },
        animate(element, target) {
            let step = 10
            let time = 10
            let gap = Math.abs(target - element.offsetLeft) / 240
            if (gap > 1) {
                step = step * gap
                time = time / gap
            }
            if (element) {
                step = element.offsetLeft > target ? -step : step
                clearInterval(this.intervalId)
                this.intervalId = setInterval(() => {
                    if (Math.abs(element.offsetLeft + step) < Math.abs(target)) {
                        element.style.left = element.offsetLeft + step + 'px'
                    } else {
                        if (Math.abs(target - element.offsetLeft) > Math.abs(step)) {
                            element.style.left = element.offsetLeft + step + 'px'
                        } else {
                            clearInterval(this.intervalId)
                            this.intervalId = null
                            element.style.left = target + 'px'
                            // 移动到目标位置，继续自动播放
                        }
                    }
                }, time)
            }
        },
        printTag() {
            if (!this.tagData.skuId) {
                this.$message.error('请选择需要打印的尺码')
                return
            }
            this.DoReceiptsModal = true
            let params = {
                id: this.tagData.skuId
            }
            this.webViewUrl = `${request.printPreviewRoot}?printerName=${this.printer}&templateCode=${this.printCode}&printCount=0&data=${encodeURI(JSON.stringify(params))}`
			let eggRootUrl = `${request.printEggPreviewRoot}?printerName=${this.printer}&templateCode=${this.printCode}&`
			const eggSrc = eggRootUrl + `printCount=${this.printCount}&data=${encodeURI(JSON.stringify(params))}&sn=${this.tagData.skuId}`;
			ipc.invoke(ipcApiRoute.print, {contentUrl: eggSrc, sn: this.tagData.skuId}).then(r => {})
        },
        rowClassNameFn({ row }) {
            if (row.spu === this.checkData.spu && row.color === this.checkData.color) {
                return 'row-highlight'
            }
            return ''
        }
    }
}
</script>

<style lang="scss">
.re-label-view {
    width: 100%;
    border: 1px solid #ddd;
    .re-label-header {
        background: #fff;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > i {
            color: #979aa0;
            cursor: pointer;
        }
        border-bottom: 1px solid #eee;
    }
    .re-label-title {
        padding-left: 10px;
        border-left: 3px solid #077cf2;
    }

    .re-label-body {
        padding: 10px;
        // background-color: #f6f6f6;
        border-bottom: 1px solid #eee;
        .el-table__row {
            cursor: pointer;
        }
    }
    .re-label-footer {
        background: #fff;
        padding: 10px 20px;
    }
    .search-box {
        padding-bottom: 10px;
        .search-item {
            display: flex;
            align-items: center;
            .search-label {
                margin-right: 10px;
                font-size: 14px;
                width: 90px;
            }
        }
        .top-btns {
            display: flex;
            justify-content: flex-end;
        }
    }
    .content-left {
        height: 649px;
        .el-table--medium .el-table__cell {
            padding: 0 !important;
            height: 23px;
        }
        .el-table-column--selection .cell {
            padding-right: 10px;
        }
        .el-table__header-wrapper {
            th.el-table__cell {
                background-color: #f9fafc;
            }
        }
        .row-highlight {
            background: #ffdd9f;
        }
        .el-pagination {
            display: flex;
            justify-content: flex-end;
            padding: 3px 5px;
            border: 1px solid #dfe6ec;
            border-top: none;
            background-color: #f9fafc;
            position: relative;
            // .el-pagination__total {
            //     flex: 1;
            // }
            .btn-next {
                margin-right: 0;
            }
        }
        .el-pagination__sizes {
            position: absolute;
            top: 0;
            left: 0;
            flex: 1;
            .el-input__inner {
                border: none;
                border-radius: 0;
                background-color: #f9fafc;
            }
        }
    }
    .content-right {
        background: #fff;
        height: 649px;
        display: flex;
        padding: 10px;

        .bar-code-list {
            width: 50px;
            height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 10px;
            .bar-code-item {
                cursor: pointer;
                width: 100%;
                text-align: center;
                height: 35px;
                line-height: 35px;
                border-bottom: 1px solid #eee;
                background-color: #ffdd9f;
            }
            .is-check {
                color: #fff;
                background-color: #077cf2;
            }
        }
        .preview-box {
            flex: 1;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            .preview-item {
                height: 280px;
                width: 240px;
                border-radius: 5px 5px 0 0;
                overflow: hidden;
                box-shadow: 0px 0px 6px #ccc;
            }
            .image-content {
                height: 257px;
                position: relative;
                .btn {
                    width: 24px;
                    height: 30px;
                    line-height: 30px;
                    background: #000;
                    opacity: 0.3;
                    color: #fff;
                    margin-top: -15px;
                    font-size: 24px;
                    text-align: center;
                    cursor: pointer;
                    z-index: 2;
                    &:hover {
                        opacity: 0.6;
                    }
                }
                .left-btn {
                    position: absolute;
                    top: 50%;
                    left: 0px;
                }
                .right-btn {
                    position: absolute;
                    top: 50%;
                    right: 0px;
                }
                .img-preview {
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    position: relative;
                }
                .url-list {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    transition: right 0.5s ease-in-out;
                    & > img {
                        width: 240px;
                        height: 100%;
                        border: none;
                    }
                }
                .url-none {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #f5f7fa;
                    font-size: 30px;
                    color: #909399;
                }
            }
        }
        .preview-title {
            text-align: center;
            background-color: #eae8e8;
            height: 23px;
            line-height: 23px;
            font-weight: bold;
        }
        .print-preview {
            height: 320px;
            width: 240px;
            border-radius: 5px 5px 0 0;
            overflow: hidden;
            box-shadow: 0px 0px 6px #ccc;
            .print-preview-content {
                width: 100%;
                height: 100%;
            }
            .print-row {
                font-size: 12px;
                font-weight: 600;
                // display: flex;
                padding: 0 4px 4px;
                display: -webkit-box;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                .print-label {
                    display: inline-block;
                    text-align: right;
                    width: 65px;
                    color: #000;
                }
                .print-value {
                    color: #333;
                    // flex: 1;
                }
            }
            .barcode-img {
                width: 100%;
                font-weight: 600;
                // display: block;
                // padding: 10px 4px 6px;
                // width: auto;
                // height: 30px;
            }
            .goods-none {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                background: #f5f7fa;
            }
        }
    }
    .re-label-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
